<template>
  <head>
    <title>الصفحة الرئيسية</title>
  </head>
  <div class="home-view">
    <!-- News slider -->
    <div
      id="carouselExampleAutoplaying"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div
          class="carousel-item"
          v-for="(item, index) in news"
          :key="index"
          :class="index == 0 ? 'active' : ''"
        >
          <div class="w-100 my-img-container">
            <div class="news-text">{{ item.text }}</div>
            <div class="mx-5"></div>
            <img
              :src="$store.state.server_images + item.image"
              alt=""
              clsas="img-bg-news"
              :style="{
                height: '150px',
                width: 'fit-content',
                borderRadius: '0px 50px 0px 50px',
              }"
            />
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <!-- About us title -->
    <div class="about-us mb-5 p-5">
      <!-- text -->
      <div class="w-100 text-end">
        <div class="about-title">
          <span style="color: whitesmoke">مصنع</span> هدس
        </div>
        <div ref="text" class="text" style="height: 200px"></div>
        <div class="about-btn" @click="$router.push('/projects')">
          تعرف علينا أكثر
        </div>
      </div>
      <!-- slider -->
      <div
        id="carouselExampleSlidesOnly1"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            class="carousel-item p-5"
            v-for="(item, index) in about_imges"
            :class="index == 0 ? 'active' : ''"
            :key="index"
          >
            <img :src="item" class="d-block about-img" alt="..." />
          </div>
        </div>
      </div>
    </div>
    <!-- Sections -->
    <div
      class="container p-5 mb-4"
      style="background-color: rgba(245, 245, 245, 0.7)"
    >
      <div class="sections">
        <div class="section-title">
          <div class="section-line">
            <div class="title-static">بعض اعمالنا</div>
            <div class="underline-static"></div>
          </div>
        </div>
        <div
          class="section-title d-flex justify-content-end align-items-center"
        >
          <div class="section-line">
            <div class="title" @click="$router.push('/products')">عرض الكل</div>
            <div class="underline"></div>
          </div>
        </div>
      </div>
      <div class="my-5" v-for="(item, index) in data" :key="index">
        <div class="item-slider">
          <!-- section title -->
          <div class="section-title my-4">
            <div class="section-line">
              <div
                v-if="index != 0"
                class="title"
                @click="$router.push('/products?id=' + item.id)"
              >
                {{ item.name }}
              </div>
              <div v-else class="title" @click="$router.push('/products')">
                {{ item.name }}
              </div>
              <div class="underline"></div>
            </div>
          </div>
          <!-- item slider -->
          <div
            :id="'carouselExampleControls' + index"
            class="carousel carousel-dark slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner card-slider">
              <div
                class="carousel-item"
                v-for="(item2, index2) in item.items"
                :key="index2"
                :class="index2 == 0 ? 'active' : ''"
              >
                <div
                  class="card-wrapper container-sm d-flex justify-content-around"
                >
                  <product-card
                    v-for="(item3, index3) in item2"
                    :key="index3"
                    :src="item3.src"
                    :name="item3.name"
                    :price_discount="item3.discount_price"
                    :price="item3.price"
                    :discount="item3.dis"
                    :id="item3.id"
                    :favourite="item3.favourite"
                  ></product-card>
                </div>
              </div>
              <button
                class="carousel-control-prev controller"
                type="button"
                :data-bs-target="'#carouselExampleControls' + index"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next controller"
                type="button"
                :data-bs-target="'#carouselExampleControls' + index"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <!-- dividor -->
        <div class="dividor" v-if="index < Object.keys(data).length - 1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/Product/ProductCards.vue";
import store from "@/store";
import axios from "axios";
export default {
  name: "home-view",
  data() {
    return {
      news: {},
      data: [],
      letters: {
        0: "في عالم يتغير بسرعة، يبحث الناس دائمًا عن الجمال والراحة في بيوتهم ومكاتبهم. ومن أكثر الخيارات التي تلبي هذه الاحتياجات هي الديكور و الأثاث الخشبي، الذي يمزج بين الأناقة والدفء والتصميم العملي.",
        1: "تصميم الجودة والتفرد يبدأ كل شيء بالتصميم. في مصنع هدس، يعمل الفريق المبدع على إنشاء قطع فريدة من نوعها تتميز بالجودة والتفرد. ونستخدم التقنيات المتقدمة والمواد عالية الجودة لإنتاج قطع تجمع بين الجمال الفني والوظيفية.",
        2: "الاختيار الدقيق للمواد تعتمد جودة الديكورات و الأثاث الخشبي بشكل كبير على المواد المستخدمة حيث نقوم باختيار أفضل أنواع الخشب لضمان المتانة والمظهر الجميل. بالإضافة إلى ذلك، يتم التركيز على استخدام مواد بيئية وصديقة للبيئة للحفاظ على البيئة وصحة العملاء.",
        3: "الدقة في التصنيع يتميز مصنع هدس بالدقة في عمليات التصنيع. حيث نستخدم معدات حديثة وتقنيات متطورة لضمان التشطيب الدقيق والجودة العالية في كل قطعة. و يُراعى في كل خطوة من خطوات التصنيع الاهتمام بالتفاصيل لضمان تقديم منتج نهائي متميز.",
        4: "الابتكار والتطوير المستمر يعتبر الابتكار والتطوير المستمر أساسيًا في عالم الديكور. و نحن وصلنا في التطوير إلى مدى بعيد حيث ادخلنا تقنية الواقع الإفتراضي في عملنا لجعل العميل يعيش التفاصيل قبل ان نحول حلمه بسحرنا إلى حقيقة.",
        5: "لالتزام بالمسؤولية الاجتماعية والبيئية يتحمل مصنع هدس مسؤوليته الاجتماعية والبيئية. من خلال أعتماده على ممارسات صناعية مستدامة واستخدام مواد صديقة للبيئة للحفاظ على البيئة وصحة العملاء والموظفين.",
        6: "استمتاع العملاء بالتميز يعتبر رضا العملاء هو الهدف الأسمى لمصنع هدس. وتتمثل رؤيتنا في تقديم تجربة تسوق فريدة وإرضاء تام للعملاء من خلال تقديم منتجات عالية الجودة وخدمة متميزة.",
        7: "مصنع هدس ليس مجرد مكان لإنتاج الأثاث و الديكور، بل هو عالم يجمع بين الإبداع والمهارة والتفاني لتقديم قطع فنية تعكس جمالية الطبيعة وراحة الحياة.",
      },
      about_imges: {
        0: require("@/assets/TestImages/AboutUs/img1.jpg"),
        1: require("@/assets/TestImages/AboutUs/img2.jpg"),
        2: require("@/assets/TestImages/AboutUs/img3.jpg"),
        3: require("@/assets/TestImages/AboutUs/img4.jpg"),
        4: require("@/assets/TestImages/AboutUs/img5.jpg"),
      },
      i: 0,
      myletter: "",
      writing_speed: 20,
      animation_speed: 1500,
      index: 0,
    };
  },
  components: { ProductCard },
  beforeRouteLeave(to, from, next) {
    // Stop the execution of WriteLetter and RemoveLetter methods
    clearTimeout(this.writeLetterTimeout);
    clearTimeout(this.removeLetterTimeout);
    next();
  },

  methods: {
    RemoveLetter() {
      this.removeLetterTimeout = setTimeout(() => {
        this.myletter = this.myletter.slice(0, this.myletter.length - 2);
        this.$refs.text.innerHTML = this.myletter + "_";
        if (this.i > 0) {
          this.i -= 1;
          this.RemoveLetter();
        } else {
          this.writeLetterTimeout = setTimeout(() => {
            this.WriteLetter();
          }, this.animation_speed);
        }
      }, this.writing_speed);
    },
    WriteLetter() {
      this.writeLetterTimeout = setTimeout(() => {
        this.myletter += this.letters[this.index][this.i];
        this.$refs.text.innerHTML = this.myletter + "_";
        if (this.i < this.letters[this.index].length - 1) {
          this.i += 1;
          this.WriteLetter();
        } else {
          if (this.index < Object.keys(this.letters).length - 1) {
            this.index += 1;
          } else {
            this.index = 0;
          }
          this.removeLetterTimeout = setTimeout(() => {
            this.RemoveLetter();
          }, this.animation_speed);
        }
      }, this.writing_speed);
    },
    getSubCategories() {
      axios
        .get(store.state.server + "sub-category-index")
        .then((response) => {
          const data = response.data;
          for (let i = 0; i < data.length; i++) {
            this.data.push({
              id: data[i].id,
              name: data[i].name,
              items: [],
            });
          }
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getNews() {
      axios
        .get(store.state.server + "news-index")
        .then((response) => {
          this.news = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getItems() {
      axios
        .get(store.state.server + "item-index")
        .then((response) => {
          const data = response.data;

          // Loop through subcategories
          for (let j = 0; j < this.data.length; j++) {
            let counter = 0;
            let counter2 = 0;
            this.data[j].items = [[]]; // Initialize items as a nested array

            // Loop through data and assign items to corresponding subcategories
            for (let i = 0; i < data.length; i++) {
              if (data[i].sub_category_id == this.data[j].id) {
                if (counter2 == 3) {
                  counter++;
                  counter2 = 0;
                  this.data[j].items.push([]); // Create a new sub-array for the next row
                }
                this.data[j].items[counter].push(data[i]);
                counter2++;
              }
            }

            // Remove subcategories with no items
            if (
              this.data[j].items.length === 1 &&
              this.data[j].items[0].length === 0
            ) {
              this.data.splice(j, 1);
              j--; // Adjust the index since the array length has changed
            }
          }

          console.log(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.getNews();
    this.getSubCategories();
    if (this.$refs.text) {
      this.WriteLetter();
    }
  },
};
</script>

<style scoped>
.news-text {
  background-color: #1919196d;
  color: whitesmoke;
  padding: 10px;
  border-radius: 10px;
}

.my-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.322);
}

.sections {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-title {
  color: rgb(23, 23, 23);
  transition: 0.5s ease-in-out;
  width: 100%;
  text-align: start;
  font-size: 26px;
  font-weight: 700;
}

.section-line {
  width: fit-content;
}

.title {
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.title-static {
  color: #c39a3e;
}

.title:hover {
  color: #c39a3e;
}

.underline {
  width: 0%;
  background-color: #c39a3e;
  height: 3px;
  transition: 0.5s ease-in-out;
}

.underline-static {
  width: 100%;
  background-color: #c39a3e;
  height: 3px;
  transition: 0.5s ease-in-out;
}

.section-line:hover .underline {
  width: 100%;
}

.card-slider {
  padding: 20px 0 20px 0;
}

.controller {
  height: 100%;
  width: 5%;
}

.dividor {
  height: 2px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  margin-top: 40px;
}

.about-title {
  font-size: 50px;
  color: #c39a3e;
}

.about-us {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-img {
  height: 300px;
  width: fit-content;
  border-radius: 50px 0 50px 0;
  filter: drop-shadow(10px 10px 10px #c39a3e);
}

.about-btn {
  background-color: #314152;
  padding: 10px 20px 10px 20px;
  font-size: 24px;
  margin-top: 10px;
  color: whitesmoke;
  width: fit-content;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.about-btn:hover {
  transform: translateX(-40px);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 1);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 1);
}

.text {
  width: 100%;
  height: 100%;
  font-size: 30px;
  color: white;
}
</style>
